import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "whitespace-pre-line" }
const _hoisted_2 = { class: "grid gap-4 grid-cols-2 grid-rows-1 mt-4" }

import { Network } from '@balancer-labs/sdk';
import { computed, ref } from 'vue';

import BalModal from '@/components/_global/BalModal/BalModal.vue';
import { urlFor } from '@/composables/useNetwork';
import useVeBAL from '@/composables/useVeBAL';

/**
 * STATE
 */

export default _defineComponent({
  setup(__props) {

const redirectModal = ref<typeof BalModal>();

/**
 * COMPOSABLES
 */
const { showRedirectModal, setShowRedirectModal } = useVeBAL();

/**
 * COMPUTED
 */
const mainnetURL = computed((): string => `${urlFor(Network.MAINNET)}/vebal`);

/**
 * METHODS
 */
function handleInternalClose() {
  redirectModal?.value?.hide();
}

return (_ctx: any,_cache: any) => {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createBlock(BalModal, {
    ref: redirectModal,
    show: _unref(showRedirectModal),
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(setShowRedirectModal)(false)))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('modals.veBalRedirectModal.title')), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('modals.veBalRedirectModal.description')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BalBtn, {
            tag: "a",
            href: _unref(mainnetURL),
            label: _ctx.$t('proceed'),
            color: "gradient"
          }, null, 8, ["href", "label"]),
          _createVNode(_component_BalBtn, {
            color: "gray",
            label: _ctx.$t('cancel'),
            outline: "",
            onClick: handleInternalClose
          }, null, 8, ["label"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})