import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-133c41ea")
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["onClick"]
_popScopeId()

import { computed, ref } from 'vue';

/**
 * TYPES
 */
type Props = {
  options: Array<any>;
  minWidth?: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    options: { type: Array, required: true },
    minWidth: { type: String, required: false, default: 'auto' }
  } as unknown as undefined,
  emits: ["selected"] as unknown as undefined,
  setup(__props: {
  options: Array<any>;
  minWidth?: string;
}, { emit }: { emit: ({
  (e: 'selected', value: any): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const showDropdown = ref(false);

/**
 * COMPUTED
 */
const dropdownClasses = computed(() => ({
  [`min-w-${props.minWidth}`]: true
}));

/**
 * METHODS
 */
function toggleDropdown(): void {
  showDropdown.value = !showDropdown.value;
}

function hideDropdown(): void {
  showDropdown.value = false;
}

function handleRowClick(option: any): void {
  emit('selected', option);
  hideDropdown();
}

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "activator",
      onClick: toggleDropdown
    }, [
      _renderSlot(_ctx.$slots, "activator")
    ]),
    (showDropdown.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['bal-dropdown', _unref(dropdownClasses)])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "bal-dropdown-row",
              onClick: ($event: any) => (handleRowClick(option))
            }, [
              _renderSlot(_ctx.$slots, "option", { option: option })
            ], 8, _hoisted_2))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 512)), [
    [_directive_click_outside, hideDropdown]
  ])
}
}

})