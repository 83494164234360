import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import { computed, ref, useAttrs } from 'vue';

/**
 * TYPES
 */
type Props = {
  noFade?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    noFade: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  setup(__props: {
  noFade?: boolean;
}) {

const props = __props


/**
 * STATE
 */
const loaded = ref(props.noFade);

/**
 * COMPOSABLES
 */
const attrs = useAttrs();

/**
 * COMPUTED
 */
const wrapperStyles = computed(() => ({
  width: attrs?.width || 'auto',
  height: attrs?.height || 'auto'
}));

/**
 * METHODS
 */
function onLoaded() {
  loaded.value = true;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "bal-image-wrapper",
    style: _normalizeStyle(_unref(wrapperStyles))
  }, [
    _createVNode(_Transition, { name: "bal-image-fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("img", _mergeProps(_ctx.$attrs, { onLoad: onLoaded }), null, 16), [
          [_vShow, loaded.value]
        ])
      ]),
      _: 1
    })
  ], 4))
}
}

})