import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import useBreakpoints from '@/composables/useBreakpoints';
import useDarkMode from '@/composables/useDarkMode';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const { isMobile } = useBreakpoints();
const { darkMode, toggleDarkMode } = useDarkMode();

return (_ctx: any,_cache: any) => {
  const _component_MoonIcon = _resolveComponent("MoonIcon")!
  const _component_SunIcon = _resolveComponent("SunIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createBlock(_component_BalBtn, {
    color: "white",
    size: _unref(isMobile) ? 'md' : 'sm',
    circle: _unref(isMobile),
    onClick: _unref(toggleDarkMode)
  }, {
    default: _withCtx(() => [
      (_unref(darkMode))
        ? (_openBlock(), _createBlock(_component_MoonIcon, { key: 0 }))
        : (_openBlock(), _createBlock(_component_SunIcon, { key: 1 }))
    ]),
    _: 1
  }, 8, ["size", "circle", "onClick"]))
}
}

})