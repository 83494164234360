import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4c5dac7e")
const _hoisted_1 = { class: "dark:bg-gray-900 lg:py-8" }
const _hoisted_2 = { class: "content-container" }
_popScopeId()

import Footer from '@/components/footer/Footer.vue';
import AppNav from '@/components/navs/AppNav/AppNav.vue';

export default _defineComponent({
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(AppNav),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ])
  ], 64))
}
}

})