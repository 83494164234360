import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-94a1f8f6")
const _hoisted_1 = ["src"]
_popScopeId()

import { computed, ref, toRefs, watch } from 'vue';

import useTokens from '@/composables/useTokens';
import useUrls from '@/composables/useUrls';
import { TokenInfo } from '@/types/TokenList';

import Avatar from '../../images/Avatar.vue';

type Props = {
  address?: string;
  iconURI?: string;
  size?: number;
  button?: boolean;
};


export default _defineComponent({
  props: {
    address: { type: String, required: false, default: '' },
    iconURI: { type: String, required: false, default: '' },
    size: { type: Number, required: false, default: 24 },
    button: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  setup(__props: {
  address?: string;
  iconURI?: string;
  size?: number;
  button?: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { getToken } = useTokens();
const { resolve } = useUrls();

/**
 * STATE
 */
const { address } = toRefs(props);
const error = ref(false);

/**
 * COMPUTED
 */
const token = computed<TokenInfo | undefined>(() => getToken(address.value));

const iconSRC = computed(() => {
  if (props.iconURI) return resolve(props.iconURI);

  if (!token.value?.logoURI) return '';
  return resolve(token.value.logoURI);
});

const rootElement = computed(() => (props.button ? 'button' : 'div'));

const rootElementAttrs = computed(() => ({
  'aria-label': token.value?.symbol
}));

/**
 * WATCHERS
 */
watch(iconSRC, newURL => {
  if (newURL !== '') error.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(rootElement)), _mergeProps({
    class: "bal-asset rounded-full inline-block leading-none shadow-sm",
    style: {
      width: `${__props.size}px`,
      height: `${__props.size}px`
    }
  }, _unref(rootElementAttrs)), {
    default: _withCtx(() => [
      (_unref(iconSRC) && !error.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _unref(iconSRC),
            onError: _cache[0] || (_cache[0] = ($event: any) => (error.value = true)),
            class: "rounded-full bg-white"
          }, null, 40, _hoisted_1))
        : (!!_unref(address))
          ? (_openBlock(), _createBlock(Avatar, {
              key: 1,
              address: _unref(address),
              size: __props.size
            }, null, 8, ["address", "size"]))
          : (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "rounded-full overflow-visible bg-gray-300 dark:bg-gray-700",
              style: _normalizeStyle({
        width: `${__props.size}px`,
        height: `${__props.size}px`
      })
            }, null, 4))
    ]),
    _: 1
  }, 16, ["style"]))
}
}

})