import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-9ec7dace")
const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_2 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_3 = {
  key: 1,
  class: "content"
}
const _hoisted_4 = { key: 0 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingIcon = _resolveComponent("BalLoadingIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(['bal-btn', _ctx.btnClasses]),
    disabled: _ctx.disabled || _ctx.loading
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_BalLoadingIcon, {
              size: _ctx.size,
              color: _ctx.iconColor
            }, null, 8, ["size", "color"]),
            (_ctx.loadingLabel)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.loadingLabel), 1))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.label)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.label), 1))
              : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
          ]))
    ]),
    _: 3
  }, 8, ["class", "disabled"]))
}