import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/logo-dark.svg'
import _imports_1 from '@/assets/images/logo-light.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  width: "30",
  class: "mr-2"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  width: "30",
  class: "mr-2"
}

import { computed } from 'vue';

import useDarkMode from '@/composables/useDarkMode';

/**
 * TYPES
 */
type Props = {
  forceDark: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    forceDark: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  forceDark: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { darkMode } = useDarkMode();

/**
 * COMPUTED
 */
const useDarkLogo = computed(() => (props.forceDark ? true : darkMode.value));

return (_ctx: any,_cache: any) => {
  return (_unref(useDarkLogo))
    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
    : (_openBlock(), _createElementBlock("img", _hoisted_2))
}
}

})