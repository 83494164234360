import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-73bb2c4b")
const _hoisted_1 = { class: "flex items-center h-full" }
const _hoisted_2 = { class: "flex flex-col justify-center h-full flex-1" }
const _hoisted_3 = ["value", "name"]
const _hoisted_4 = {
  key: 0,
  value: "",
  hidden: ""
}
const _hoisted_5 = ["value"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bal-select-input', _ctx.containerClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.label || _ctx.$slots.label)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['label', _ctx.labelClasses])
            }, [
              _renderSlot(_ctx.$slots, "label", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ], {}, true)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("select", _mergeProps({
          ref: "balSelectInput",
          value: _ctx.modelValue,
          name: _ctx.name,
          class: [_ctx.inputClasses]
        }, _ctx.$attrs, {
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }), [
          (_ctx.defaultText)
            ? (_openBlock(), _createElementBlock("option", _hoisted_4, _toDisplayString(_ctx.defaultText), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
            return (_openBlock(), _createElementBlock("option", {
              key: i,
              value: _ctx.valFor(option),
              class: ""
            }, _toDisplayString(_ctx.textFor(option)), 9, _hoisted_5))
          }), 128))
        ], 16, _hoisted_3)
      ]),
      _createVNode(_component_BalIcon, { name: "chevron-down" })
    ])
  ], 2))
}