import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-col text-right" }

import { computed } from 'vue';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';

/**
 * TYPES
 */
type Props = {
  address: string;
  selected: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    address: { type: String, required: true },
    selected: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  address: string;
  selected: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { getToken, balanceFor } = useTokens();
const { fNum2, toFiat } = useNumbers();

/**
 * COMPUTED
 */
const token = computed(() => getToken(props.address));

const balanceLabel = computed(() =>
  fNum2(balanceFor(props.address), FNumFormats.token)
);

const fiatLabel = computed(() => {
  const tokenBalance = balanceFor(props.address);
  const fiatValue = toFiat(tokenBalance, props.address);

  return fNum2(fiatValue, FNumFormats.fiat);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['flex justify-between', { 'text-gray-400': !__props.selected }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_unref(token).symbol), 1),
      (_unref(token).name !== _unref(token).symbol)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(['text-sm', __props.selected ? 'text-gray-500' : 'text-gray-400'])
          }, _toDisplayString(_unref(token).name), 3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_unref(balanceLabel)), 1),
      _createElementVNode("span", {
        class: _normalizeClass(['text-sm', __props.selected ? 'text-gray-500' : 'text-gray-400'])
      }, _toDisplayString(_unref(fiatLabel)), 3)
    ])
  ], 2))
}
}

})