<template>
  <BalModal show title="Your address is blocked from transacting on this site">
    <p class="pb-3 text-sm">
      Your wallet address cannot use this site because it has been flagged as
      high risk by our compliance partner, TRM Labs.
    </p>
    <p class="pb-3 text-sm">
      This website is open source and permissionless. Anyone can fork and run
      their own front end.
    </p>
  </BalModal>
</template>

<script setup lang="ts"></script>
