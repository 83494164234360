<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.888584 8.06059L7.77779 14.9498L14.667 8.06059L13.211 6.59752L8.83603 10.9796V0.333313H6.71955V10.9796L2.33745 6.59752L0.888584 8.06059Z"
      fill="currentColor"
    />
  </svg>
</template>
