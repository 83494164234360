import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-032e31e0")
const _hoisted_1 = { class: "text-sm pb-3" }
const _hoisted_2 = { class: "text-sm font-medium" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "capitalize" }
const _hoisted_5 = { class: "text-sm" }
_popScopeId()

type Props = {
  isVisible: boolean;
};


export default _defineComponent({
  props: {
    isVisible: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  isVisible: boolean;
}) {



const services = [
  'infura',
  'alchemy',
  'the-graph',
  'google-analytics',
  'fathom-analytics',
  'TRM-labs',
  'sentry'
];

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: __props.isVisible,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    title: "Use of 3rd party services",
    class: "third-parties"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('policies.balancerThirdPartyInfo')) + ".", 1),
      _createVNode(_component_BalStack, {
        vertical: "",
        class: "pb-2"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('policies.usesFollowing')) + " " + _toDisplayString(_ctx.$t('policies.thirdPartyServices')) + ": ", 1),
          _createVNode(_component_BalStack, {
            vertical: "",
            class: "pl-2"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(services, (service) => {
                return _createVNode(_component_BalStack, {
                  spacing: "base",
                  horizontal: "",
                  key: service,
                  align: "start"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      width: "36",
                      height: "36",
                      src: require(`@/assets/images/services/${service}.svg`),
                      alt: "Balancer 3rd party service",
                      class: "mt-1"
                    }, null, 8, _hoisted_3),
                    _createVNode(_component_BalStack, {
                      vertical: "",
                      spacing: "none"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h6", _hoisted_4, _toDisplayString(service.replaceAll('-', ' ')), 1),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(`services.${service}`)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              }), 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})