import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex items-center",
  style: {"width":"70%"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-gray-700 dark:text-white text-base" }
const _hoisted_4 = { class: "capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "wallet-connect-btn",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: require(`@/assets/images/connectors/${_ctx.wallet}.svg`),
        class: "h-10 w-10 mr-4"
      }, null, 8, _hoisted_2),
      _createElementVNode("h5", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.WalletNameMap[_ctx.wallet]), 1)
      ])
    ])
  ]))
}