<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 11844 11844"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <rect
      id="Artboard5"
      x="0"
      y="-0"
      width="11843.8"
      height="11843.8"
      style="fill:none;"
    />
    <g>
      <path
        d="M9706.02,3397.8l275.318,-5.443c159.668,13.722 314.087,149.91 234.793,284.504c-247.153,419.518 -757.209,1360.35 -1172.77,2174.35c-447.031,875.655 -984.678,2000.71 -2312.94,2445.62c-0,0 -1555.07,632.966 -2738.85,-634.304l5714.45,-4264.73Z"
        style="fill:url(#_Linear1);"
      />
      <path
        id="_1"
        serif:id="1"
        d="M6388.14,8322.01c-483.397,121.822 -1301.53,210.021 -2139.64,-417.747l-0.153,-0.115c-165.36,-120.229 -389.575,-331.797 -922,-1021.68c-237.842,-285.347 -600.861,-664.772 -1070.29,-960.05l-0.001,0c0,0 -402.926,-267.897 -605.711,-344.422c-40.466,-15.271 -44.938,-48.762 -43.998,-55.477c0.706,-5.049 6.303,-37.499 43.998,-43.166c553.521,-83.206 907.941,-174.833 1045.86,-209.362c488,-110.123 1076.27,-271.487 1707.49,-505.755l186.65,-79.964c334.048,-143.11 1137,-481.062 1204.28,-501.039c293.803,-85.672 936.112,-266.467 1676.88,-433.969l0.075,-0.054l0,-0c74.462,-16.84 149.913,-33.544 226.13,-50.011c287.239,-61.049 751.316,-154.195 1164.68,-214.504l-5.06,4.657c424.34,-64.743 836.603,-99.799 1121.89,-100.43c61.297,0.078 149.124,10.296 171.082,79.549c38.5,121.422 -43.29,112.483 -543.654,1052.43c-435.114,699.682 -877.234,1707.48 -1245.75,2267.23l-1.491,2.125l0,0.001c-409.129,644.023 -1002.91,1255.32 -1971.27,1531.75Z"
        style="fill:url(#_Linear2);"
      />
      <path
        id="_11"
        serif:id="1"
        d="M2696.21,5269.99c302.436,104.638 724.186,271.366 1181.13,514.846c-511.967,112.588 -1071.72,175.324 -1621.28,137.581c0,0 -402.926,-267.897 -605.711,-344.422c-40.466,-15.271 -44.938,-48.762 -43.998,-55.477c0.706,-5.049 6.303,-37.499 43.998,-43.166c553.521,-83.206 907.941,-174.833 1045.86,-209.362Z"
        style="fill:#7bbffd;"
      />
      <path
        id="_2"
        serif:id="2"
        d="M3877.34,5784.84c480.731,256.153 1000.52,597.239 1461.93,1040.54c-503.29,126.807 -1251.62,215.481 -2012.91,57.094c-237.842,-285.347 -600.861,-664.772 -1070.29,-960.05c549.561,37.743 1109.31,-24.993 1621.28,-137.581Z"
        style="fill:url(#_Linear3);"
      />
      <path
        id="_3"
        serif:id="3"
        d="M5339.26,6825.37c424.607,408.003 799.552,902.388 1048.88,1496.63c-483.397,121.822 -1301.53,210.021 -2139.64,-417.747l-0.153,-0.115c-165.36,-120.229 -389.575,-331.797 -922,-1021.68c761.286,158.387 1509.62,69.713 2012.91,-57.094Z"
        style="fill:url(#_Linear4);"
      />
      <path
        id="_21"
        serif:id="2"
        d="M4403.69,4764.24c359.103,58.226 807.558,157.231 1294.3,325.515c-346.769,225.486 -1026.06,520.336 -1820.66,695.086c-456.944,-243.48 -878.694,-410.208 -1181.13,-514.846c488,-110.123 1076.27,-271.487 1707.49,-505.755Z"
        style="fill:url(#_Linear5);"
      />
      <path
        id="_31"
        serif:id="3"
        d="M5697.99,5089.75c432.761,149.621 895.839,353.98 1353.55,633.135c-527.468,582.638 -1009.39,864.949 -1369.91,998.209c-94.308,34.86 -209.832,70.889 -342.367,104.279c-461.273,-443.235 -981.096,-784.348 -1461.93,-1040.54c794.62,-174.746 1473.94,-469.615 1820.66,-695.086Z"
        style="fill:url(#_Linear6);"
      />
      <path
        id="_4"
        serif:id="4"
        d="M7051.54,5722.89c452.813,276.186 900.263,625.493 1307.87,1067.37c-409.129,644.023 -1002.91,1255.32 -1971.27,1531.75c-249.327,-594.246 -624.272,-1088.63 -1048.88,-1496.63c132.535,-33.39 248.059,-69.419 342.367,-104.279c360.517,-133.26 842.444,-415.571 1369.91,-998.209Z"
        style="fill:url(#_Linear7);"
      />
      <path
        id="_32"
        serif:id="3"
        d="M6903.24,4167.38c-512.044,384.018 -924.211,711.399 -1096.6,845.651c-31.345,24.409 -67.699,50.102 -108.643,76.724c-486.741,-168.284 -935.196,-267.289 -1294.3,-325.515c0,-0 1194.06,-511.566 1199.05,-513.599c369.816,-46.023 810.593,-81.231 1300.5,-83.261Z"
        style="fill:url(#_Linear8);"
      />
      <path
        id="_41"
        serif:id="4"
        d="M6903.24,4167.38c386.057,-1.6 802.65,17.38 1239.32,67.875c-120.262,146.729 -229.584,296.242 -321.583,445.811c-261.307,424.825 -520.458,766.806 -769.436,1041.82c-457.706,-279.173 -920.815,-483.541 -1353.55,-633.135c40.944,-26.622 77.298,-52.315 108.643,-76.724c172.393,-134.252 584.56,-461.633 1096.6,-845.651Z"
        style="fill:url(#_Linear9);"
      />
      <path
        id="_5"
        serif:id="5"
        d="M8142.56,4235.25c469.341,54.284 961.739,144.974 1464.09,285.649c-435.114,699.682 -877.234,1707.48 -1245.75,2267.23l-0.274,0.39l-1.217,1.735c-407.604,-441.88 -855.053,-791.186 -1307.87,-1067.37c248.978,-275.018 508.129,-616.999 769.436,-1041.82c91.999,-149.569 201.321,-299.082 321.583,-445.811Z"
        style="fill:url(#_Linear10);"
      />
      <path
        id="_42"
        serif:id="4"
        d="M7471.5,3749.26c-197.658,142.408 -389.104,283.749 -568.261,418.112c31.164,-0.129 62.526,-0.124 94.129,0.071c-527.777,-2.468 -1001.33,34.245 -1394.62,83.19c-4.988,2.033 121.659,-46.554 191.876,-67.404c293.803,-85.672 936.112,-266.467 1676.88,-433.969Z"
        style="fill:#f29fff;"
      />
      <path
        id="_51"
        serif:id="5"
        d="M7697.71,3699.2c287.239,-61.049 751.316,-154.195 1164.68,-214.504c-250.517,230.419 -502.955,485.953 -719.826,750.556c-436.656,-50.504 -853.319,-69.485 -1239.32,-67.875c179.175,-134.377 370.641,-275.732 568.336,-418.166l0,-0c74.462,-16.84 149.913,-33.544 226.13,-50.011Z"
        style="fill:#ff5ca8;"
      />
      <path
        id="_6"
        serif:id="6"
        d="M9979.22,3388.92c61.297,0.078 149.124,10.296 171.082,79.549c38.5,121.422 -43.29,112.483 -543.654,1052.43c-482.092,-132.814 -971.464,-219.523 -1464.09,-285.649c210.039,-256.268 471.92,-521.306 714.766,-745.899c424.34,-64.743 836.603,-99.799 1121.89,-100.43Z"
        style="fill:#f40041;"
      />
      <path
        d="M2696.21,5269.99c488,-110.123 1076.27,-271.487 1707.49,-505.755c0,-0 1194.06,-511.566 1199.05,-513.599c369.816,-46.023 810.593,-81.231 1300.5,-83.261c-512.044,384.018 -924.211,711.399 -1096.6,845.651c-31.345,24.409 -67.699,50.102 -108.643,76.724c-346.769,225.486 -1026.06,520.336 -1820.66,695.086c-511.967,112.588 -1071.72,175.324 -1621.28,137.581c0,0 -402.926,-267.897 -605.711,-344.422c-40.466,-15.271 -44.938,-48.762 -43.998,-55.477c0.706,-5.049 6.303,-37.499 43.998,-43.166c553.521,-83.206 907.941,-174.833 1045.86,-209.362Z"
        style="fill:url(#_Linear11);"
      />
    </g>
    <defs>
      <linearGradient
        id="_Linear1"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-4069.29,4912.23,-4912.23,-4069.29,10096.6,3409.78)"
      >
        <stop offset="0" style="stop-color:#8600b3;stop-opacity:1" />
        <stop offset="0.2" style="stop-color:#a621b4;stop-opacity:1" />
        <stop offset="0.42" style="stop-color:#c843b6;stop-opacity:1" />
        <stop offset="1" style="stop-color:#d853b7;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear2"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-3084.66,-3052.02,3052.02,-3084.66,6174.46,8322.01)"
      >
        <stop offset="0" style="stop-color:#a84cc0;stop-opacity:1" />
        <stop offset="0.53" style="stop-color:#9e66ce;stop-opacity:1" />
        <stop offset="1" style="stop-color:#7abffd;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear3"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(550.985,-1040.54,1040.54,550.985,3326.35,6825.37)"
      >
        <stop offset="0" style="stop-color:#4645c2;stop-opacity:1" />
        <stop offset="1" style="stop-color:#5b45c0;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear4"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(458.246,-1415.86,1415.86,458.246,4857.25,8247.15)"
      >
        <stop offset="0" style="stop-color:#4f00e2;stop-opacity:1" />
        <stop offset="1" style="stop-color:#4a00b4;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear5"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1974.31,-814.593,814.593,1974.31,3119,5722.89)"
      >
        <stop offset="0" style="stop-color:#7abffd;stop-opacity:1" />
        <stop offset="1" style="stop-color:#ab95fd;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear6"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(619.566,-1608.15,1608.15,619.566,5388.16,6812.74)"
      >
        <stop offset="0" style="stop-color:#714bd0;stop-opacity:1" />
        <stop offset="1" style="stop-color:#b34cbe;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear7"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1133.23,-2236.09,2236.09,1133.23,6433.26,8308.81)"
      >
        <stop offset="0" style="stop-color:#6a00dc;stop-opacity:1" />
        <stop offset="1" style="stop-color:#a90080;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear8"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1073.03,-758.727,758.727,1073.03,5231.64,4945.08)"
      >
        <stop offset="0" style="stop-color:#c49cfe;stop-opacity:1" />
        <stop offset="1" style="stop-color:#dd91fe;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear9"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1095.51,-1195.25,1195.25,1095.51,6375.99,5362.63)"
      >
        <stop offset="0" style="stop-color:#cd53bd;stop-opacity:1" />
        <stop offset="1" style="stop-color:#e954af;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear10"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(928.697,-2178.93,2178.93,928.697,8142.56,6567.28)"
      >
        <stop offset="0" style="stop-color:#b80089;stop-opacity:1" />
        <stop offset="1" style="stop-color:#df0051;stop-opacity:1" />
      </linearGradient>
      <linearGradient
        id="_Linear11"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-977.613,-2578.27,2578.27,-977.613,5697.99,7206.83)"
      >
        <stop offset="0" style="stop-color:#fff;stop-opacity:0.36" />
        <stop offset="1" style="stop-color:#fff;stop-opacity:0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.logotype-path {
  fill: #0f172a; /* gray-900 */
  transition: fill 0.2s ease-out;
}

.dark .logotype-path {
  fill: #fff;
}

.logotype:hover .logotype-path {
  fill: theme('colors.purple.700');
}

.dark .logotype:hover .logotype-path {
  fill: theme('colors.yellow.500');
}
</style>
