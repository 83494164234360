import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import VeBalRedirectModal from './VeBalRedirectModal.vue';

export default _defineComponent({
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
    _createVNode(VeBalRedirectModal)
  ]))
}
}

})