import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a2f4a8ec")
const _hoisted_1 = { class: "relative p-3 overflow-hidden rounded bg-white dark:bg-gray-800 shadow-lg text-sm dark:text-white dark:border-gray-850 w-64" }
const _hoisted_2 = { class: "justify-between group" }
const _hoisted_3 = { class: "font-semibold flex items-center mb-1" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "message" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "font-semibold title mb-1" }
const _hoisted_8 = { class: "message" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalCloseIcon = _resolveComponent("BalCloseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.notification.transactionMetadata)
        ? (_openBlock(), _createBlock(_component_BalLink, {
            key: 0,
            href: _ctx.notification.transactionMetadata.explorerLink,
            external: "",
            noStyle: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.notification.title), 1),
                _createVNode(_component_BalIcon, {
                  name: "arrow-up-right",
                  size: "sm",
                  class: "ml-1 text-gray-400 dark:text-gray-600 group-hover:text-pink-500 transition-colors"
                })
              ]),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.notification.message), 1)
            ]),
            _: 1
          }, 8, ["href"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.notification.title), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.notification.message), 1)
          ])),
      _createVNode(_component_BalCloseIcon, {
        class: "cursor-pointer text-black dark:text-white flex-shrink-0 absolute top-3 right-2",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeNotification()))
      })
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.progressClasses),
      style: _normalizeStyle({ width: `${(_ctx.progress * 100).toFixed(0)}%` })
    }, null, 6)
  ]))
}