import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0801c1d3")
const _hoisted_1 = { class: "flex items-center balancer-logotype sm:mr-4" }
_popScopeId()

import LogotypeBalancer from '@/components/icons/LogotypeBalancer.vue';

export default _defineComponent({
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LogotypeBalancer)
  ]))
}
}

})