import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-1 flex md:justify-center fade-in-slow" }
const _hoisted_2 = { class: "alert-label" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-nav-alert', _ctx.classes]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.iconName)
        ? (_openBlock(), _createBlock(_component_BalIcon, {
            key: 0,
            name: _ctx.iconName,
            class: "mr-3"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.alert.label), 1),
        (_ctx.alert.action && _ctx.alert.actionLabel)
          ? (_openBlock(), _createBlock(_component_BalBtn, {
              key: 0,
              class: "cursor-pointer",
              color: "white",
              size: "xs",
              label: _ctx.alert.actionLabel,
              onClick: _ctx.alert.action
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (!_ctx.alert.persistent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_BalIcon, {
            name: "x",
            class: "cursor-pointer mt-0.5 fade-in-slow",
            onClick: _withModifiers(_ctx.handleClose, ["stop"])
          }, null, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}