import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-3 text-sm" }
const _hoisted_2 = { class: "\r\n        p-4\r\n        rounded-lg\r\n        bg-gradient-to-b\r\n        from-gray-50\r\n        dark:from-gray-900\r\n        to-gray-100\r\n        dark:to-gray-850\r\n      " }
const _hoisted_3 = { class: "text-sm" }
const _hoisted_4 = { class: "align-middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_WalletButton = _resolveComponent("WalletButton")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: _ctx.isVisible,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
    title: "Connect to a wallet"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('byConnectingWallet')) + " ", 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('useOf')) + " ", 1),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onShowThirdParty && _ctx.onShowThirdParty(...args)))
        }, [
          _createVNode(_component_BalLink, null, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('policies.thirdPartyServices')), 1)
            ]),
            _: 1
          })
        ]),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('and')), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wallets, (wallet) => {
        return (_openBlock(), _createBlock(_component_WalletButton, {
          wallet: wallet,
          key: wallet
        }, null, 8, ["wallet"]))
      }), 128)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('newToEthereum')), 1),
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t('setUpEthereumWallet')) + " ", 1),
          _createVNode(_component_BalLink, {
            href: _ctx.EXTERNAL_LINKS.Ethereum.Wallets,
            external: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('learnMore')) + " ", 1),
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_BalIcon, {
                  name: "arrow-up-right",
                  size: "sm"
                })
              ])
            ]),
            _: 1
          }, 8, ["href"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}