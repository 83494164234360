import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-body" }
const _hoisted_2 = { class: "pb-16" }

import Footer from '@/components/footer/Footer.vue';
import AppNav from '@/components/navs/AppNav/AppNav.vue';

export default _defineComponent({
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(AppNav),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view, {
          key: _ctx.$route.path
        })
      ]),
      (_ctx.isDesktop)
        ? (_openBlock(), _createBlock(_component_BalBtn, {
            key: 0,
            id: "intercom-activator",
            circle: "",
            size: "lg",
            color: "blue",
            class: "fixed bottom-0 right-0 m-4 z-100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BalIcon, {
                name: "message-square",
                size: "lg"
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})