
import { computed, defineComponent, PropType, ref } from 'vue';

import { RuleFunction, Rules } from '@/types';

import BalIcon from '../BalIcon/BalIcon.vue';

export default defineComponent({
  name: 'BalSelectInput',

  emits: ['change', 'update:modelValue'],

  components: {
    BalIcon
  },

  props: {
    modelValue: { type: String, default: '' },
    options: { type: Array, required: true },
    name: { type: String, required: true },
    label: { type: String, default: '' },
    validateOn: { type: String, default: 'change' },
    defaultText: { type: String, default: '' },
    noMargin: { type: Boolean, default: false },
    size: {
      type: String,
      default: 'md',
      validator: (val: string): boolean => ['sm', 'md', 'lg'].includes(val)
    },
    rules: {
      type: Array as PropType<Rules>,
      default: () => []
    }
  },

  setup(props, { emit }) {
    const balSelectInput = ref({} as HTMLSelectElement);
    const errors = ref([] as string[]);

    const hasError = computed(() => errors.value.length > 0);

    const textSizeClasses = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'text-sm';
        case 'lg':
          return 'text-lg';
        default:
          return 'text-base';
      }
    });

    const labelSizeClasses = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'text-xs -mt-4';
        case 'lg':
          return 'text-base -mt-8';
        default:
          return 'text-sm -mt-6';
      }
    });

    const inputHeightClasses = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'h-10';
        case 'lg':
          return 'h-16';
        default:
          return 'h-10';
      }
    });

    const containerClasses = computed(() => {
      return {
        [textSizeClasses.value]: true,
        [inputHeightClasses.value]: true,
        'mb-5': !props.noMargin
      };
    });

    const inputClasses = computed(() => {
      return {};
    });

    const labelClasses = computed(() => {
      return {
        [labelSizeClasses.value]: true
      };
    });

    function valFor(option) {
      if (typeof option === 'string' || option instanceof String) return option;
      return option.value;
    }

    function textFor(option) {
      if (typeof option === 'string' || option instanceof String) return option;
      return option.text;
    }

    function onChange(event): void {
      emit('change', event.target.value);
      emit('update:modelValue', event.target.value);
    }

    function validate(val: string): void {
      errors.value = [];
      props.rules.forEach((rule: RuleFunction) => {
        const result = rule(val);
        if (typeof result === 'string') errors.value.push(result);
      });
    }

    return {
      balSelectInput,
      hasError,
      containerClasses,
      inputClasses,
      valFor,
      textFor,
      validate,
      labelClasses,
      onChange
    };
  }
});
