import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bal-icon inline-block" }
const _hoisted_2 = ["data-feather", "width", "height", "fill"]

import feather from 'feather-icons';
import { computed, onMounted } from 'vue';

/**
 * TYPES
 */
export type IconSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Props = {
  name: string;
  size?: IconSize;
  filled?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    name: { type: String, required: true },
    size: { type: String, required: false, default: 'md' },
    filled: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  setup(__props: {
  name: string;
  size?: IconSize;
  filled?: boolean;
}) {

const props = __props


/**
 * COMPUTED
 */
const iconSize = computed(() => {
  switch (props.size) {
    case 'xxs':
      return '8';
    case 'xs':
      return '12';
    case 'sm':
      return '16';
    case 'lg':
      return '24';
    case 'xl':
      return '28';
    default:
      return '20';
  }
});

const fill = computed(() => (props.filled ? 'currentColor' : 'none'));

/**
 * LIFECYCLE
 */
onMounted(() => feather.replace());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      "data-feather": __props.name,
      width: _unref(iconSize),
      height: _unref(iconSize),
      fill: _unref(fill)
    }, null, 8, _hoisted_2)
  ]))
}
}

})