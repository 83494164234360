<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9767 12.79C21.8194 14.4922 21.1805 16.1144 20.1349 17.4668C19.0893 18.8192 17.6802 19.8458 16.0724 20.4265C14.4646 21.0073 12.7247 21.1181 11.0562 20.7461C9.3877 20.3741 7.85967 19.5345 6.6509 18.3258C5.44213 17.117 4.60262 15.589 4.23058 13.9205C3.85855 12.252 3.96939 10.5121 4.55014 8.9043C5.13088 7.29651 6.15751 5.88737 7.50989 4.84175C8.86227 3.79614 10.4845 3.15731 12.1867 3C11.1901 4.34827 10.7105 6.00945 10.8352 7.68141C10.9599 9.35338 11.6805 10.9251 12.8661 12.1106C14.0516 13.2961 15.6233 14.0168 17.2953 14.1415C18.9672 14.2662 20.6284 13.7866 21.9767 12.79Z"
      fill="url(#moon-gradient)"
    />
    <defs>
      <linearGradient
        id="moon-gradient"
        x1="13.9768"
        y1="8.5"
        x2="6.47678"
        y2="23"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFDEB" />
        <stop offset="1" stop-color="#FFB800" />
      </linearGradient>
    </defs>
  </svg>
</template>
